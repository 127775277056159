@font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsRegular"),
   url("./fonts/poppins/Poppins-Regular.ttf") format("truetype");
  /* font-weight: bold; */
 }



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-position: ; */
  background: rgb(211,244,255);
  background: linear-gradient(158deg, rgba(58, 219, 180, 0.3472163865546218) -20%, rgba(0, 158, 165, 0.8) 70%) no-repeat center center fixed;
  
   font-family: "PoppinsRegular";
  
   overflow-wrap: break-word;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.noScroll{
  position: fixed;
}
